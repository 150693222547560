import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { useCurrentUser } from '~/data/api/hooks/users'
import type { PreferredDistanceUnit, PreferredTimeFormat } from '~/data/types/preferredUnits'
import type { TimeZone } from '~/data/types/timezone'
import { timezones } from '~/data/types/timezone'

export type TimeFormat = 'hh:mm' | 'HH:mm'
export type TzDayjs = (date: dayjs.ConfigType) => Dayjs

export function useUserPrefs() {
  const { synopUser } = useCurrentUser()

  const {
    preferredDateFormat: maybeDateFormat,
    preferredDistanceUnits: maybeDistanceUnits,
    preferredTemperatureUnits: maybeTemperatureUnits,
    preferredTimeFormat: maybeTimeFormat,
    preferredTimeZone
  } = synopUser ?? {}

  const preferredDateFormat = maybeDateFormat || 'MM/DD/YY'
  const preferredDistanceUnits = maybeDistanceUnits || 'Imperial (Feet / Miles)'
  const preferredTemperatureUnits = maybeTemperatureUnits || 'fahrenheit'
  const preferredTimeFormat = maybeTimeFormat || '12 Hour Clock (01:00 - 12:00)'
  const mappedTimeFormat = timeFormatMap[preferredTimeFormat]

  const timezone = useMemo(() => {
    return preferredTimeZone && timezones.includes(preferredTimeZone)
      ? preferredTimeZone
      : (dayjs.tz.guess() as TimeZone)
  }, [preferredTimeZone])

  /** Given a date, returns a dayjs object with the user's preferred timezone set */
  const tzDayjs = useCallback((date?: dayjs.ConfigType) => dayjs(date).tz(timezone), [timezone])

  return {
    preferredDateFormat,
    preferredHourFormat: mappedTimeFormat.slice(0, 1),
    preferredTemperatureUnits,
    preferredTimeFormat: mappedTimeFormat,
    preferredTimeZone: timezone,
    preferredUnitSystem: getPreferredUnitSystem(preferredDistanceUnits),
    tzDayjs
  }
}

const timeFormatMap: Record<PreferredTimeFormat, TimeFormat> = {
  '12 Hour Clock (01:00 - 12:00)': 'hh:mm',
  '24 Hour Clock (00:00 - 23:59)': 'HH:mm'
}

export type UnitSystem = 'imperial' | 'metric'
function getPreferredUnitSystem(preferredDistanceUnits: PreferredDistanceUnit): UnitSystem {
  return preferredDistanceUnits.startsWith('Imperial') ? 'imperial' : 'metric'
}
