import dayjs, { type ConfigType } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import emDash from '~/components/emdash'
import useTimeFormatter from '~/hooks/useTimeFormatter'
import { type TimeFormat } from '~/hooks/useUserPrefs'

import type { TimeRange } from '../time/types'
import type { PreferredDateFormat, TimeZone } from '../types'
import { replaceMap } from './string'

dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isToday)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

type FormatTimePreferences = {
  preferredDateFormat: PreferredDateFormat
  preferredTimeFormat: TimeFormat
  preferredTimeZone: TimeZone
}

type PartialOptions = Partial<TimeOptions>
type TimeOptions = {
  displaySeconds: boolean
  displayMeridiemIndicator: boolean
  dropLeadingZero: boolean
  invalidDateValue: string
  displayMilliseconds?: boolean
  displayTimeZone?: string
}

const defaultTimeOptions: Omit<TimeOptions, 'displayMeridiemIndicator'> = {
  displaySeconds: false,
  dropLeadingZero: false,
  invalidDateValue: emDash
}

const isTimeOptions = (maybeDate: ConfigType | PartialOptions): maybeDate is PartialOptions => {
  const isDate = maybeDate instanceof Date
  const isDayjs = maybeDate instanceof dayjs
  if (isDate || isDayjs || typeof maybeDate !== 'object' || !maybeDate) return false
  return true
}

function validateTimeZone(timeZone?: string) {
  try {
    dayjs().tz(timeZone)
    return timeZone
  } catch (error) {
    return null
  }
}

export type DateFormatter = ReturnType<typeof formatDateTime>
export default function formatDateTime({
  preferredDateFormat,
  preferredTimeFormat,
  preferredTimeZone
}: FormatTimePreferences) {
  return (
    fromDate: ConfigType,
    maybeToDate: ConfigType | PartialOptions = defaultTimeOptions,
    options?: PartialOptions
  ) => {
    const toDate: ConfigType = isTimeOptions(maybeToDate) ? null : maybeToDate

    const timeOptions: TimeOptions = {
      ...defaultTimeOptions,
      displayMeridiemIndicator: preferredTimeFormat === 'hh:mm',
      ...(isTimeOptions(maybeToDate) ? maybeToDate : options)
    }

    const displayTimeZone = validateTimeZone(timeOptions.displayTimeZone) ?? preferredTimeZone
    const { displaySeconds, displayMeridiemIndicator, invalidDateValue } = timeOptions
    const secondsFormat = displaySeconds ? ':ss' : ''
    const meridiemFormat = displayMeridiemIndicator ? ' a' : ''
    const timeOfDayFormat = timeOptions?.dropLeadingZero
      ? preferredTimeFormat.replaceAll('hh', 'h')
      : preferredTimeFormat
    const timeFormat = `${timeOfDayFormat}${secondsFormat}${meridiemFormat}`

    const maybeString = (value: unknown, noValueString: string) => (returnValue: string) => {
      return value ? returnValue : noValueString
    }
    const maybeFrom = maybeString(fromDate, invalidDateValue)
    const maybeFromTo = maybeString(fromDate && toDate, invalidDateValue)

    return {
      options: timeOptions,
      /* SINGLE DATE FORMATS */
      fromDateTime: dayjs(fromDate).tz(displayTimeZone),
      get date() {
        return maybeFrom(this.fromDateTime.format(preferredDateFormat))
      },
      get dateDataExport() {
        return this.fromDateTime.isValid() ? this.fromDateTime.format('YYYY-MM-DD') : ''
      },
      get datetimeDataExport() {
        return this.fromDateTime.isValid()
          ? this.fromDateTime.format(`YYYY-MM-DD HH:mm:ss${this.options.displayMilliseconds ? '.SSS' : ''}`)
          : ''
      },
      get time() {
        return maybeFrom(this.fromDateTime.format(timeFormat))
      },
      get asTimeOnDate() {
        return maybeFrom(`As of ${this.timeOnDate}`)
      },
      get atTimeOnDate() {
        return maybeFrom(`At ${this.timeOnDate}`)
      },
      get dateDotTime() {
        return maybeFrom(`${this.date} • ${this.time}`)
      },
      get timeDotDate() {
        return maybeFrom(`${this.time} • ${this.date}`)
      },
      get timeOnDate() {
        return maybeFrom(`${this.time} on ${this.date}`)
      },

      get longFormDate() {
        const dateFormat = replaceMap(preferredDateFormat, {
          YY: 'YYYY',
          MM: 'MMM',
          DD: 'D',
          '/': ' '
        })

        return maybeFrom(this.fromDateTime.format(dateFormat))
      },

      /* MULTIPLE DATE FORMATS */
      toDateTime: dayjs(toDate).tz(displayTimeZone),
      get toDate() {
        return maybeFromTo(this.toDateTime.format(preferredDateFormat))
      },
      get toTime() {
        return maybeFromTo(this.toDateTime.format(timeFormat))
      },
      get betweenTimes() {
        return maybeFromTo(`${this.time} - ${this.toTime}`)
      },
      get betweenDates() {
        const dateRange = this.fromDateTime.isSame(this.toDateTime, 'day') ? this.date : `${this.date} - ${this.toDate}`
        return maybeFromTo(dateRange)
      },
      get fromToDayOnDate() {
        const rangeStartDate = !this.fromDateTime.isSame(toDate, 'day') ? `on ${this.date} ` : ''
        return maybeFromTo(`From ${this.time} ${rangeStartDate}to ${this.toTime} on ${this.toDate}`)
      },
      get betweenLongFormDateTimes() {
        const dateFormat = preferredDateFormat.replaceAll('YY', 'YYYY').replaceAll('MM', 'MMMM').replaceAll('/', ' ')
        const timeFormat = preferredTimeFormat === 'hh:mm' ? 'hh:mm a' : preferredTimeFormat
        const format = `${dateFormat} ${timeFormat}`
        return maybeFromTo(
          `${this.fromDateTime.format(format)} -
           ${this.toDateTime.format(format)}`
        )
      }
    }
  }
}

export type DateFormat =
  | 'asTimeOnDate'
  | 'atTimeOnDate'
  | 'date'
  | 'dateDataExport'
  | 'datetimeDataExport'
  | 'dateDotTime'
  | 'longFormDate'
  | 'time'
  | 'timeDotDate'
  | 'timeOnDate'

type DateFormatterProps = PartialOptions & {
  value: ConfigType
  format?: DateFormat
}

export function DateFormatter({ value, format = 'dateDotTime', ...options }: DateFormatterProps) {
  const formatDateTime = useTimeFormatter()
  const formatted = formatDateTime(value, options)[format]
  return <>{formatted}</>
}

export type DateRangeFormat = 'fromToDayOnDate' | 'betweenTimes' | 'betweenLongFormDateTimes' | 'betweenDates'
type DateRangeFormatterProps = PartialOptions & {
  range?: TimeRange
  format?: DateRangeFormat
}

export function DateRangeFormatter({ range, format = 'fromToDayOnDate', ...options }: DateRangeFormatterProps) {
  const formatDateTime = useTimeFormatter()
  return range ? <>{formatDateTime(range.from, range.to, options)[format]}</> : null
}
