import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { useCurrentOrgId } from '~/store/slices/currentOrg'
import { dispatcherFactory, selectorHookFactory } from '~/store/utils'

type UserDashboardState = {
  activeDashboard: 'chargers' | 'vehicles'
  filters: {
    chargers: {
      orgIds: string[]
      siteIds: string[]
    }
    vehicles: {
      orgIds: string[]
    }
  }
}

type DashboardSlice = {
  currentUser?: string
  userStates: {
    [key: string]: UserDashboardState
  }
}

const initialUserState: UserDashboardState = {
  activeDashboard: 'chargers',
  filters: {
    chargers: {
      orgIds: [],
      siteIds: []
    },
    vehicles: {
      orgIds: []
    }
  }
}

const initialState: DashboardSlice = {
  userStates: {}
}

function getCurrentUserState(state: DashboardSlice) {
  if (!state.currentUser) return { ...initialUserState }

  let userState = state.userStates[state.currentUser]
  if (!userState) {
    userState = { ...initialUserState }
    state.userStates[state.currentUser] = userState
  }

  return userState
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: (state) => {
      const userState = getCurrentUserState(state)
      if (userState.activeDashboard === 'chargers') {
        userState.filters.chargers = initialUserState.filters.chargers
      } else {
        userState.filters.vehicles = initialUserState.filters.vehicles
      }
    },
    resetAll: (state) => {
      const userState = getCurrentUserState(state)
      userState.filters = initialUserState.filters
    },
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.currentUser = action.payload
      if (!state.userStates[action.payload]) state.userStates[action.payload] = { ...initialUserState }
    },
    setActiveDashboard: (state, action: PayloadAction<'chargers' | 'vehicles'>) => {
      const userState = getCurrentUserState(state)
      userState.activeDashboard = action.payload
    },
    setOrgIds: (state, action: PayloadAction<string[]>) => {
      const userState = getCurrentUserState(state)
      userState.filters[userState.activeDashboard].orgIds = action.payload
    },
    setSiteIds: (state, action: PayloadAction<string[]>) => {
      const userState = getCurrentUserState(state)
      if (userState.activeDashboard === 'chargers') {
        userState.filters.chargers.siteIds = action.payload
      }
    }
  }
})

export default dashboardSlice.reducer

export const useActiveDashboard = selectorHookFactory((state) => getCurrentUserState(state.dashboard).activeDashboard)
export const useSelectedOrgIds = selectorHookFactory((state) => {
  const userState = getCurrentUserState(state.dashboard)
  return userState.filters[userState.activeDashboard].orgIds
})

// No vehicle site IDs so just use chargers by default
export const useSelectedSiteIds = selectorHookFactory(
  (state) => getCurrentUserState(state.dashboard).filters.chargers.siteIds
)
export const useFilterCount = selectorHookFactory((state) => {
  const userState = getCurrentUserState(state.dashboard)
  if (userState.activeDashboard === 'chargers') {
    return (
      (userState.filters.chargers.orgIds.length > 0 ? 1 : 0) + (userState.filters.chargers.siteIds.length > 0 ? 1 : 0)
    )
  } else {
    return userState.filters.vehicles.orgIds.length > 0 ? 1 : 0
  }
})

const { reset, resetAll, setCurrentUser, setActiveDashboard, setOrgIds, setSiteIds } = dashboardSlice.actions
export { reset, resetAll }

export const useUpdateActiveDashboard = dispatcherFactory(setActiveDashboard)
export const useUpdateOrgIds = dispatcherFactory(setOrgIds)
export const useUpdateSiteIds = dispatcherFactory(setSiteIds)
export const useSetCurrentUser = dispatcherFactory(setCurrentUser)

/** Returns the selected org ID or the current org ID if no org is selected */
export function useSelectedOrgIdOrCurrent() {
  const orgIds = useSelectedOrgIds()
  const orgId = useCurrentOrgId()
  return orgIds.length > 0 ? orgIds[0] : orgId
}
