import type { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import type { UseQuery as UseQueryRtk } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useEffect, useState } from 'react'

import useWindowFocus from '~/hooks/useWindowFocus'

import type { BaseQuery } from '../client/emptyRootAPI'

const DEFAULT_POLLING_INTERVAL = 60

export type UseQueryOptions = { skip?: boolean }
export type UsePollingOptions = UseQueryOptions & { intervalSeconds?: number; ignoreFocus?: boolean }

type UseQuery<ApiArg, ApiResponse> = UseQueryRtk<QueryDefinition<ApiArg, BaseQuery, never, ApiResponse, 'api'>>

/**
 * Hook used to poll an API endpoint while the user is focused on the page.
 */
export function usePolling<ApiArgs, ApiResponse>(
  useQuery: UseQuery<ApiArgs, ApiResponse>,
  args: ApiArgs,
  options: UsePollingOptions = {}
) {
  const { intervalSeconds = DEFAULT_POLLING_INTERVAL, ignoreFocus, ...rest } = options
  const intervalMilliseconds = intervalSeconds * 1000

  const isWindowFocused = useWindowFocus()
  const [pollingInterval, setPollingInterval] = useState(isWindowFocused ? intervalMilliseconds : 0)

  // When the window loses focus, stop polling. When it regains focus, start polling again.
  useEffect(() => {
    setPollingInterval(isWindowFocused ? intervalMilliseconds : 0)
  }, [isWindowFocused, intervalMilliseconds])

  const results = useQuery(args, {
    pollingInterval: ignoreFocus ? intervalMilliseconds : pollingInterval,
    refetchOnMountOrArgChange: true,
    ...rest
  })
  const { currentData, refetch } = results

  useEffect(() => {
    if (currentData && !options.skip && pollingInterval > 0) refetch()
    // Disable exhaustive-deps because while we only want to refetch
    // when there is already data to be updated, we don't want to refetch
    // every time the data itself changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingInterval])

  return results
}
